import { useEffect, useState } from "react";

// Update this to a Laravel endpoint once one exists
const API_ENDPOINT = "https://message-set-creator.vestaboard.com";

interface IQuietHours {
  quietHoursBegin: string;
  quietHoursEnd: string;
  isQuietHours: boolean;
  timezone: string;
  formattedQuietHours: string;
}

interface IUseGetQuietHours {
  subscriptionConfigurationToken: string;
}

export const useGetQuietHours = (input: IUseGetQuietHours) => {
  const [data, setData] = useState<IQuietHours | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!input.subscriptionConfigurationToken) {
      return;
    }

    fetch(`${API_ENDPOINT}/quiet-hours`, {
      headers: {
        "x-vestaboard-subscription-configuration-token":
          input.subscriptionConfigurationToken,
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(setData)
      .then(() => setLoading(false));
  }, [input.subscriptionConfigurationToken]);

  return {
    data,
    loading,
  };
};
