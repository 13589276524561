import { Box, CircularProgress } from "@mui/material";
import {
  ActionButton,
  BoardPreview,
  Body,
  Button,
  Container,
  Divider,
  encodeBoardCharacters,
  Input,
  InstallableContainer,
  Medium,
  Modal,
  NotificationBanner,
  Spacer,
  SubTitle,
  Theme,
  Title,
  Toast,
  ToggleButton,
} from "@vestaboard/installables";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  QueryParamProvider,
  StringParam,
  useQueryParam,
} from "use-query-params";
import "./App.css";
import { MirrorInstallableSettings } from "./MirrorInstallableSettings";
import { useCopy } from "./utils";
import { useGetQuietHours } from "./hooks/useGetQuietHours";

function App() {
  return (
    <Theme>
      <QueryParamProvider>
        <Router>
          <Routes>
            <Route path="/default-config" element={<DefaultConfig />} />
            <Route path="/settings">
              <Route path="mirror" element={<MirrorBoardSettings />} />
            </Route>
            <Route path="/installable">
              <Route path="mirror">
                <Route
                  path="settings"
                  element={<MirrorInstallableSettings />}
                />
              </Route>
            </Route>
          </Routes>
        </Router>
      </QueryParamProvider>
    </Theme>
  );
}

interface IConfig {
  requiresConfiguration: boolean;
  setupMessage?: string;
  setupBody?: string;
  setupCtaUrl?: string;
  confirmedBody?: string;
  confirmedMessage?: string;
  setupCtaLabel?: string;
  quietHours?: string;
}

const useBoardMirrorSettings = (boardId: string) => {
  const [publishToken] = useQueryParam("publishToken", StringParam);
  const [mirrorEnabled, setMirrorEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);

  const save = async (enabled: boolean) => {
    setSaving(true);
    setMirrorEnabled(enabled);
    const response = await fetch(
      `https://8d9d6393.installables.vbrd.net/board/${boardId}/settings`,
      {
        method: "PUT",
        body: JSON.stringify({
          mirrorEnabled: enabled,
        }),
      }
    );
    setSaving(false);
  };

  const get = async () => {
    setLoading(true);
    const data = await fetch(
      `https://8d9d6393.installables.vbrd.net/board/${boardId}/settings?publishToken=${publishToken}`
    );
    const json = await data.json();
    setMirrorEnabled(json.board.mirrorEnabled);
    setLoading(false);
  };

  React.useEffect(() => {
    get();
  }, []);

  return {
    publishToken,
    boardId,
    mirrorEnabled,
    loading,
    save,
  };
};

const MirrorBoardSettings: React.FC = (props) => {
  const [boardId] = useQueryParam("boardId", StringParam);
  const [enabled, setEnabled] = React.useState<
    undefined | "disabled" | "enabled"
  >(undefined);
  const [copiedToastShown, setCopiedToastShown] = React.useState(false);
  const { copy } = useCopy();

  const showCopiedToast = () => {
    setCopiedToastShown(true);
    setTimeout(() => {
      setCopiedToastShown(false);
    }, 5000);
  };

  const { save, loading, publishToken, mirrorEnabled } = useBoardMirrorSettings(
    boardId ?? ""
  );

  React.useEffect(() => {
    if (!loading) {
      setEnabled(mirrorEnabled ? "enabled" : "disabled");
    }
  }, [loading, mirrorEnabled]);

  if (loading) return <CircularProgress />;

  return (
    <>
      <Toast
        message="Your Mirror Code has been copied."
        autoHideDurationSeconds={5}
        onClose={() => setCopiedToastShown(false)}
        severity="success"
        open={copiedToastShown}
      />
      <Container>
        <Spacer size="large" />
        <Title>Allow Mirroring</Title>
        <Spacer size="medium" />
        <Medium>
          Enable mirroring to share your Vestaboard content with other
          Vestaboards.
        </Medium>
        <Spacer size="medium" />
        <Medium>
          You or people you know can use your unique code inside the Vestaboard+
          Mirroring channel to keep other Vestaboards in sync with this one.
        </Medium>
        <Spacer size="medium" />
        <Medium>
          You can revoke Mirroring at any time by disabling from this screen.
        </Medium>
        <Spacer size="large" />
        {enabled ? (
          <div style={{ width: 300 }}>
            <ToggleButton
              onToggle={(v) => {
                setEnabled(v as "disabled" | "enabled");
                save(v === "enabled" ? true : false);
              }}
              options={[
                { value: "disabled", key: "Disabled" },
                { value: "enabled", key: "Enabled" },
              ]}
              value={enabled}
            />
          </div>
        ) : (
          <CircularProgress />
        )}
        <Spacer size="large" />
        {enabled == "enabled" ? (
          <>
            <Divider />
            <Spacer size="large" />
            <Box>
              <Input
                onValueChange={() => {}}
                multiline
                readOnly
                value={publishToken ?? ""}
                label={"Mirror Code"}
              />
              <Spacer size="large" />
              <Button
                width={200}
                buttonType="white"
                onClick={async () => {
                  publishToken && (await copy(publishToken));
                  showCopiedToast();
                }}
              >
                Copy
              </Button>
            </Box>
          </>
        ) : (
          <span />
        )}
        <Spacer size="medium" />
      </Container>
    </>
  );
};
// If this than that uses this component
const DefaultConfig: React.FC = (props) => {
  const { search } = useLocation();
  const [openPreview, setOpenPreview] = useState(false);

  let config: IConfig | null = null;

  try {
    config = JSON.parse(atob(search.substring(1).split("&")[0]));
  } catch (e) {
    config = null;
  }

  if (!config) return <span />;
  // IFTTT
  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          padding: 16,
          paddingTop: 32,
          backgroundColor: "#25282a",
        }}
      >
        <Box
          style={{
            textAlign: "left",
            width: "100%",
            maxWidth: 600,
          }}
        >
          {config?.quietHours ? (
            <InstallableContainer>
              <Spacer size="medium" />
              <div style={{}}>
                <NotificationBanner
                  visible={true}
                  text={config.quietHours || ""}
                />
              </div>
              <Spacer size="medium" />
            </InstallableContainer>
          ) : null}
          <SubTitle>Description</SubTitle>
          <Spacer size="medium" />
          <Body>
            Receive automated messages from your favorite third-party services
            by linking Vestaboard to your IFTTT account. This channel connects
            to a third party website.
          </Body>
          <Spacer size="medium" />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <ActionButton
              onClick={() => {
                setOpenPreview(true);
              }}
            >
              Preview
            </ActionButton>
            <div style={{ width: 16 }}></div>
            <ActionButton
              onClick={() => {
                window.open("https://www.vestaboard.com/help/ifttt");
              }}
            >
              Help
            </ActionButton>
          </Box>
          <Spacer size={"large"} />
          <Divider />
          <Spacer size={"large"} />
          <SubTitle>
            {config.requiresConfiguration
              ? "Authorization Needed"
              : "Authorization Complete"}
          </SubTitle>
          <Spacer size="medium" />
          <Body>
            {config.requiresConfiguration
              ? "To begin using IFTTT with your Vestaboard, we’ll need you to authorize access to your IFTTT account first. Click the button below to start the process or create a free account today."
              : "You have authorized this Vestaboard to be used with IFTTT. Visit IFTTT to manage."}
          </Body>
          <Spacer size="extraLarge" />
          {!!config.setupCtaUrl && (
            <>
              <a
                href={config.setupCtaUrl}
                rel="noopener noreferer"
                target="_blank"
                className="button-link full-width"
              >
                {config.requiresConfiguration
                  ? "Authorize IFTTT"
                  : "Manage IFTTT"}
              </a>
            </>
          )}
        </Box>
      </Box>
      <Modal
        visible={openPreview}
        transparent
        fullScreenMobile
        onClose={() => {
          setOpenPreview(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <BoardPreview
            isFavorite={false}
            characters={encodeBoardCharacters(
              `                                             Dad is almost home!   Head to the garage.`
            )}
          />
        </Box>
      </Modal>
    </>
  );
};

export default App;
