import { CircularProgress } from "@mui/material";
import {
  Container,
  Input,
  Spacer,
  Title,
  Medium,
  Button,
  Divider,
  NotificationBanner,
} from "@vestaboard/installables";
import * as React from "react";
import { useLocation } from "react-router";
import { StringParam, useQueryParam } from "use-query-params";
import { useGetQuietHours } from "./hooks/useGetQuietHours";

export const MirrorInstallableSettings: React.FC = (props) => {
  const { data, error, loading, update, reload } =
    useMirrorSubscriptionSettings();
  const [saving, setSaving] = React.useState(false);
  const [publishToken, setPublishToken] = React.useState("");
  const [publishTokenExists, setPublishTokenExists] = React.useState(false);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlParams);
  const { data: quietHoursData } = useGetQuietHours({
    subscriptionConfigurationToken:
      params.subscription_configuration_token || "",
  });

  React.useEffect(() => {
    setPublishTokenExists(data?.subscription?.publishToken?.exists ?? false);
  }, [data]);

  const save = async () => {
    setSaving(true);
    await update(publishToken.trim());
    setTimeout(() => {
      reload();
      setSaving(false);
    }, 2000);
  };

  const startOver = () => {
    setPublishTokenExists(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Container>
        <>
          {quietHoursData?.isQuietHours ? (
            <>
              <Spacer size="large" />
              <div
                style={{
                  maxWidth: 400,
                }}
              >
                <NotificationBanner
                  visible={quietHoursData?.isQuietHours || true}
                  text={quietHoursData?.formattedQuietHours || ""}
                />
              </div>
            </>
          ) : null}
        </>
        <Spacer size="large" />
        <Title>Mirroring</Title>
        {!publishTokenExists ? (
          <>
            <Spacer size="medium" />
            <Medium>
              Keep your Vestaboard in sync with another Vestaboard.
            </Medium>
            <Spacer size="medium" />
            <Medium>
              Paste the unique mirror code that has been copied from another
              Vestaboard to get started.
            </Medium>
            <Spacer size="medium"></Spacer>
            <Input
              onValueChange={(v: string) => setPublishToken(v)}
              multiline
              value={publishToken ?? ""}
              error={error ? "Invalid Publish Token" : undefined}
              label="Mirror Code"
            />
            <Spacer size="medium" />
            <Button onClick={() => save()} buttonType="white" width={200}>
              {saving ? <CircularProgress /> : "Save"}
            </Button>
          </>
        ) : (
          <>
            {data?.subscription.originBoardId.disabled ? (
              <>
                <Spacer size="medium" />
                <Medium>
                  <span style={{ color: "red" }}>
                    {data?.subscription?.originBoardId?.title} has disabled
                    mirroring.
                  </span>
                </Medium>
                <Spacer size="medium" />
                <Medium>
                  Keep your Vestaboard in sync with another Vestaboard instead.
                </Medium>
                <Spacer size="medium"></Spacer>
                <Button onClick={() => startOver()}>Start Over</Button>
              </>
            ) : (
              <>
                <Spacer size="medium" />
                <Medium>
                  Your Vestaboard is now mirroring{" "}
                  {data?.subscription?.originBoardId?.title}. All messages sent
                  to {data?.subscription?.originBoardId?.title} will be shown on
                  this Vestaboard as well.
                </Medium>
                <Spacer size="medium" />
              </>
            )}
          </>
        )}
        <Spacer size="large" />
        <Divider />
        <Spacer size="large" />
        <Medium>
          <strong>
            Want to mirror content from this Vestaboard to others?
          </strong>
        </Medium>
        <Spacer size="medium" />
        <Medium>
          Enable and share your unique mirror code within the{" "}
          <a
            style={{
              color: "#FFF",
            }}
            href="#"
            onClick={() =>
              window.open(
                `https://platform.vestaboard.com/deeplink/settings/dynamic/mirroring`
              )
            }
          >
            Settings
          </a>{" "}
          section.
        </Medium>
      </Container>
    </>
  );
};

const MIRROR_INSTALLABLE_BASE_URL =
  "https://8d9d6393.installables.vbrd.net/settings";

const useMirrorSubscriptionSettings = () => {
  const [data, setData] = React.useState<any>({});
  const { subscriptionConfigToken, subscriptionId } =
    useVestaboardQueryParams();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const load = async () => {
    setLoading(true);
    const response = await fetch(
      `${MIRROR_INSTALLABLE_BASE_URL}/${subscriptionId}/${subscriptionConfigToken}`
    );
    const json = await response.json();
    setError(response.ok ? false : true);
    setData(json);
    setError(false);
    setLoading(false);
  };

  React.useEffect(() => {
    try {
      load();
    } catch (e) {}
  }, []);

  const update = async (newPublishToken: string) => {
    setSaving(true);
    const data = await fetch(
      `${MIRROR_INSTALLABLE_BASE_URL}/${subscriptionId}/${subscriptionConfigToken}`,
      {
        method: "PUT",
        body: JSON.stringify({
          publishToken: newPublishToken,
        }),
      }
    );
    const json = await data.json();
    setSaving(false);
  };

  return {
    data,
    loading,
    saving,
    error,
    update,
    reload: load,
  };
};

export const useVestaboardQueryParams = () => {
  const [subscriptionId] = useQueryParam("subscription_id", StringParam);
  const [subscriptionConfigToken] = useQueryParam(
    "subscription_configuration_token",
    StringParam
  );
  const [platform] = useQueryParam("platform", StringParam);

  return {
    subscriptionId,
    subscriptionConfigToken,
    platform,
  };
};
